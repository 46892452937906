
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faExclamationCircle, faInfoCircle, faTimesCircle }
    from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

library.add(faInfoCircle);
library.add(faExclamationCircle);
library.add(faTimesCircle);
library.add(faCheckCircle);

@Component({
    components: {
        FontAwesomeIcon: FontAwesomeIcon
    }
})
export default class Alert extends Vue {
    @Prop({required: true})
    public message!: string;

    @Prop({default: null})
    public details!: string | null;

    @Prop({required: true})
    public type!: "info" | "warning" | "error" | "success";

    public get icon(): IconDefinition {
        switch(this.type) {
            case "info": return faInfoCircle;
            case "warning": return faExclamationCircle;
            case "error": return faTimesCircle;
            case "success": return faCheckCircle;
        }
        throw new Error();
    }

    public showDetails = false;

    public toggleDetails() : void {
        this.showDetails = !this.showDetails;
    }
}
