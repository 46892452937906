
import Alert from '@/components/Alert.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
      Alert: Alert,
    },
})
export default class ErrorView extends Vue {
    @Prop({required: true})
    public endUserMessage!: string;

    @Prop({default: null})
    public technicalMessage!: string | null;
}
